import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Dialog, Button, Tooltip,
} from 'sw-ui';

import Input from '../../../../components/input';
import AlertTP from '../../../../components/tpDetails/alert';
import { FlatButton } from '../../../../components/button';
import { VatDialog } from './compoments/VatDialog';
import { VatErrorDialog } from './compoments/VatErrorDialog';
import { VatBlock } from './compoments/VatBlock';
import { Currency } from './components/Currency';
import CommonFields from './commonFields';

import {
  dateAddMinutes, diffDays, isValidMomentObject, isMoment, formatDate, momentObject,
} from '../../../../bi/utils/formatDate';
import {
  applyAnalytics, removeAnalytics, validateAnalytics, validateSingleAnalytics,
} from '../../../../bi/utils/customAnalytics';
import {
  dateAfterOtherDate,
  fullDateAfterOtherDate,
  getDefaultValidation,
  getRegionId,
  getVatErrorText,
  prepareMealForSave,
} from '../../../../bi/utils/hotels';
import {
  findDepartments,
  setDateInMomentPenalties,
  isIntNumberOrDash,
  isEmptyPenalties,
  preventKeyNotNumber,
} from '../../../../bi/utils/trip';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';
import { isMandatory } from '../../../../bi/utils/account';

import {
  FIELDS, TRIPSLABELS, PRICELABELS, INN_LENGHTS,
} from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import { timePattern, numbersAndColon } from '../../../../bi/constants/regExp';
import {
  HOTEL_PROVIDER_IS_AUTO_CALC_VAT,
  HOTEL_PROVIDER_VALUE,
  PROVIDERS_AVAILABLE_INN,
  CURRENCY_VALUES,
} from '../../../../bi/constants/hotel';
import {
  FULLTIME, SHORT_DEFAULT_DATE, DATE_WITHOUT_TIME_ZONE,
} from '../../../../constants/time';
import {
  MILLISECONDS_IN_DAY, VAT_ERROR_STATUS, VAT_VALUE_BY_VAT_BLOCK, VATVALUE,
} from '../../../../bi/constants/hotels';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/form.module.scss';

const ERRORS = {
  INVALID_CHARACTER: 'недопустимые символы',
  INVALID_FORMAT: 'недопустимый формат',
};

const FIELD_VALUE = 'Питание не включено';

class NewHotelForm extends Component {
  static propTypes = {
    hotel: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    tripService: PropTypes.object.isRequired,
    travelPolicyService: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    projects: PropTypes.array,
    mandatoryProject: PropTypes.array,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    addTrip: PropTypes.func.isRequired,
    guidService: PropTypes.object.isRequired,
    isItemCart: PropTypes.bool,
    labelButton: PropTypes.string,
    accountId: PropTypes.number.isRequired,
    isCopy: PropTypes.bool,
  };

  static defaultProps = {
    companies: [],
    projects: [],
    mandatoryProject: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    isItemCart: false,
    labelButton: TRIPSLABELS.CONFIRM,
    isCopy: false,
  };

  constructor(props) {
    super(props);

    if (props.hotel.isEdit) {
      const { JsonData, CancellationPenalties } = props.hotel;

      JsonData.CheckinDate = moment(JsonData.CheckinDate);
      JsonData.CheckoutDate = moment(JsonData.CheckoutDate);
      JsonData.ReservationDate = moment(JsonData.ReservationDate);
      JsonData.Room.FreeCancellation = JsonData.Room.FreeCancellation
        ? moment(JsonData.Room.FreeCancellation)
        : null;

      this.state = {
        ...props.hotel,
        CancellationPenalties: CancellationPenalties ? setDateInMomentPenalties(CancellationPenalties) : null,
        validation: getDefaultValidation(),
      };
    } else {
      const hotel = this.getHotel(props.hotel);

      this.state = {
        ...hotel,
        PriceDetails: {
          ...hotel.PriceDetails,
          hasVat: '',
        },
        isNewTrip: true,
        contract: null,
        connected: null,
        employeesList: [],
        mandatoryProject: [],
        errors: {
          'Hotel.CheckinTime': '',
          'Hotel.CheckoutTime': '',
        },
        showVatDialog: false,
        showNotificationDialog: false,
        internalVatDialogValue: {
          RackRate: 0,
          Rate: '',
          Amount: 0,
        },
        preBasePrice: 0,
      };
    }

    this.providerNameOptions = props.tripService.getHotelProviderName();
  }

  componentDidMount() {
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);
  }

  componentDidUpdate() {
    const {
      JsonData: { Room: { FreeCancellation } },
      CancellationPenalties,
    } = this.state;

    if (FreeCancellation && CancellationPenalties && CancellationPenalties.length < 2) {
      CancellationPenalties[0].From = dateAddMinutes(FreeCancellation, 1);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.hotel.OrderTripItemId !== this.state.OrderTripItemId) {
      this.setState(this.getHotel(newProps.hotel));
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ employeesList }) => {
    this.setState({ employeesList });
  };

  getHotel = (hotel) => {
    const { isCopy } = this.props;

    const data = hotel.JsonData;
    const {
      ProviderName, PriceDetails, Room: { CancellationPenalties },
    } = data;

    const isAandaProvider = HOTEL_PROVIDER_VALUE.aanda === ProviderName;
    const isVerdehoProvider = HOTEL_PROVIDER_VALUE.verdeho === ProviderName;
    const fitCommission = (isAandaProvider && PriceDetails) ? PriceDetails.Commission : hotel.PriceDetails.commission;
    const currentPenalties = ((isAandaProvider && CancellationPenalties) || isCopy)
      ? setDateInMomentPenalties(CancellationPenalties)
      : null;

    const state = {
      OrderTripItemId: hotel.OrderTripItemId,
      ServiceType: hotel.ServiceType,
      Status: 0,
      Employees: hotel.Employees,
      EmployeeId: 0,
      JsonData: data,
      PriceDetails: {
        ...hotel.PriceDetails,
        commission: fitCommission,
      },
      Contact: hotel.Contact || '',
      isReturnFunds: true,
      EmployeesList: [{}],
      CompanyId: 0,
      ProjectId: 0,
      DepartmentId: 0,
      NightsCount: 0,
      NightsPrice: 0,
      Departments: [],
      UserAnalytics: [],
      ProviderInn: '',
      ReportAmount: hotel.isFromOrderByNumber ? data.ReportAmount : 0,
      hotelName: {
        label: data.Hotel.Name || '',
        selected: {},
        suggests: [],
      },
      CustomHotelName: data.Hotel.Name || '',
      CountryCode: hotel.CountryCode || '',
      CurrencyData: {
        Base: hotel.BaseInCurrency || 0,
        Commission: hotel.CommissionInCurrency || 0,
        Total: hotel.TotalInCurrency || 0,
        CurrencyName: CURRENCY_VALUES.USD,
        CurrencyValue: hotel.CurrencyValue || '',
        CurrencyMultiplierPercent: hotel.CurrencyMultiplierPercent || '',
        CurrencyValueWithMultiplier: hotel.CurrencyValueWithMultiplier || '',
        DateUTC: hotel.CurrencyDateUTC || '',
        Multiplier: hotel.Multiplier || hotel.CurrencyMultiplier || '',
        MFInCurrency: hotel.MFInCurrency || 0,
        MF: hotel.MF || 0,
        Inclusive: hotel.Inclusive || 0,
        RequiredTotalInRub: 0,
      },
      rates: [],
      ratePlaneId: 0,
      CancellationPenalties: hotel.CancellationPenalties && hotel.CancellationPenalties.length
        ? hotel.CancellationPenalties
        : currentPenalties,
      travellers: hotel.Travellers,
      showBigDateIntervalDialog: false,
      showTooltipCalculate: false,
      showAlertTP: false,
      validation: getDefaultValidation(),
      InternalVat: hotel.InternalVat,
      Meal: hotel.Meal,
      IsFromOrderByNumber: hotel.isFromOrderByNumber,
    };

    if (isAandaProvider) state.JsonData.Room.Category = data.RoomName;

    if (isVerdehoProvider) {
      state.InternalVat.VatReady = false;
      state.InternalVat.VatInfo = null;
      state.PriceDetails.hasVat = VATVALUE.NO_DATA;
    }

    state.JsonData.CheckinDate = moment(state.JsonData.CheckinDate);
    state.JsonData.CheckoutDate = moment(state.JsonData.CheckoutDate);
    state.JsonData.ReservationDate = moment(state.JsonData.ReservationDate);
    state.JsonData.Room.FreeCancellation = state.JsonData.Room.FreeCancellation
      ? moment(state.JsonData.Room.FreeCancellation)
      : null;

    return state;
  };

  getTLRates = async ({ Id }) => {
    const { tripService: { getTravellineRates } } = this.props;
    const { JsonData: { ProviderName } } = this.state;

    if (ProviderName === HOTEL_PROVIDER_VALUE.travelline) {
      const { rates } = await getTravellineRates(Id);

      this.setState((prevState) => ({
        ...prevState,
        rates,
        ratePlaneId: rates ? rates[0].id : 0,
      }));
    }
  };

  normalizeCancellationPenalties = () => {
    const { JsonData: { ProviderName }, CancellationPenalties } = this.state;

    if (ProviderName === HOTEL_PROVIDER_VALUE.expedia) {
      return CancellationPenalties.map(({
        From,
        Total,
        Base,
        Additional,
        BaseCurrency,
        BaseInCurrency,
        MF,
        MFInCurrency,
        MFRubPenalties,
        ProviderPrice,
      }) => ({
        From: From.format(FULLTIME),
        Total: parseFloat(Total, 10),
        Base: parseFloat(Base, 10),
        Additional,
        BaseCurrency: parseFloat(BaseCurrency, 10),
        BaseInCurrency: parseFloat(BaseInCurrency, 10),
        MF: parseFloat(MF, 10),
        MFInCurrency: parseFloat(MFInCurrency, 10),
        MFRubPenalties: parseFloat(MFRubPenalties, 10),
        ProviderPrice: parseFloat(ProviderPrice, 10),
      }));
    }

    return CancellationPenalties.map(({
      From, Total, Base, Additional,
    }) => ({
      From: From.format(FULLTIME),
      Total: parseFloat(Total, 10),
      Base: parseFloat(Base, 10),
      Additional,
    }));
  };

  normalizeDate(JsonData) {
    const { CancellationPenalties } = this.state;
    const { isItemCart } = this.props;

    const newJsonData = JsonData;
    const checkDate = formatDate(newJsonData.CheckinDate, FULLTIME);
    const checkoutDate = formatDate(newJsonData.CheckoutDate, FULLTIME);
    const reservationDate = typeof newJsonData.ReservationDate === 'string'
      ? formatDate(newJsonData.ReservationDate, FULLTIME)
      : newJsonData.ReservationDate;
    const roomCancel = typeof newJsonData.Room.FreeCancellation === 'string'
      ? formatDate(newJsonData.Room.FreeCancellation, FULLTIME)
      : newJsonData.Room.FreeCancellation;
    const cancellationPenalty = newJsonData.Room.FreeCancellation && CancellationPenalties
      ? this.normalizeCancellationPenalties()
      : [];

    newJsonData.CheckinDate = checkDate || null;
    newJsonData.CheckoutDate = checkoutDate || null;
    if (isItemCart) {
      newJsonData.ReservationDate = '';
    } else {
      newJsonData.ReservationDate = reservationDate ? reservationDate.format(FULLTIME) : reservationDate;
    }
    newJsonData.Room.FreeCancellation = roomCancel ? roomCancel.format(FULLTIME) : roomCancel;
    newJsonData.Room.CancellationPenalties = cancellationPenalty;
  }

  resetMainHotelFields = () => {
    this.setState({
      hotelName: {
        label: '',
        selected: {},
        suggests: [],
      },
      CustomHotelName: '',
      contract: {},
      connected: {},
      compared: {},
      JsonData: {
        ...this.state.JsonData,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name: '',
          Address: '',
          City: '',
          Phone: '',
          Stars: '',
          CheckinTime: '',
          CheckoutTime: '',
          ClassificatorId: null,
        },
      },
    });
  };

  handleChangeNumberInput = (field, value) => {
    if ((value === '' || isIntNumberOrDash(value)) && value.length <= INN_LENGHTS.MAX) {
      this.setState({ [field]: value });
    }
  };

  handleChangeReportAmount = (field, value) => {
    this.setState({ [field]: typeof value === 'number' ? parseFloat(value.toFixed(2)) : value });
  };

  handleChangeJsonInput = (field, value) => {
    const { JsonData } = this.state;
    let newValue = value;

    const part = field.split('.');

    if (typeof value === 'number') {
      newValue = parseFloat(value.toFixed(2));
    }

    if (part.length === 1) {
      JsonData[field] = newValue;
    } else {
      JsonData[part[0]][part[1]] = newValue;
    }

    this.setState({ JsonData }, () => this.handleCalculateBase());
  };

  handleChangeTimeInput(e, field, value) {
    const { JsonData } = this.state;

    if (value.length === 0 || numbersAndColon.test(value)) {
      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: '',
        },
      }));
      const part = field.split('.');

      JsonData[part[0]][part[1]] = value;
      this.setState({ JsonData }, () => this.handleCalculateBase());
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: ERRORS.INVALID_CHARACTER,
        },
      }));
    }
  }

  handleBlurTimeInput(e, field) {
    const value = e.target.value;

    if (value.length === 0 || timePattern.test(value)) {
      const { JsonData } = this.state;
      const { tripService } = this.props;
      const isDateBeforeOtherDate = !fullDateAfterOtherDate(JsonData);

      if (isDateBeforeOtherDate) {
        const resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.HOTEL_CHECKOUT_TIME_LESS, JsonData);

        return this.setState((prevState) => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [FIELDS.HOTELCHECKOUTTIME]: resultCheckoutLess,
          },
        }));
      }

      return this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: '',
          [FIELDS.HOTELCHECKOUTTIME]: JsonData.Hotel.CheckoutTime && '',
        },
      }));
    }

    return this.setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [field]: ERRORS.INVALID_FORMAT,
      },
    }));
  }

  handleChangePriceInput = (e, field, value) => {
    const { PriceDetails } = this.state;

    let newValue = value;

    if (typeof value === 'number') {
      newValue = parseFloat(value.toFixed(2));
    }

    this.setState({
      PriceDetails: {
        ...PriceDetails,
        [field]: newValue,
      },
    }, () => {
      if (field !== 'Base') {
        this.handleCalculateBase();
      }
    });
  };

  handleCalculateBase = () => {
    const {
      JsonData: {
        NightsCount,
        NightsPrice,
        CustomCheckInDate,
        CustomCheckOutDate,
      },
      PriceDetails: {
        EarlyCheckIn,
        LateCheckOut,
      },
    } = this.state;

    if (NightsCount > 0 && NightsPrice > 0) {
      let earlyCheckInPrice = 0;
      let lateCheckOutPrice = 0;

      if (CustomCheckInDate) {
        earlyCheckInPrice = Number(EarlyCheckIn);
      }

      if (CustomCheckOutDate) {
        lateCheckOutPrice = Number(LateCheckOut);
      }

      const earlyAndLateChecksSum = earlyCheckInPrice + lateCheckOutPrice;
      const price = parseFloat((NightsCount * NightsPrice).toFixed(2)) + earlyAndLateChecksSum;

      this.setState({
        PriceDetails: {
          ...this.state.PriceDetails,
          Base: price,
        },
      });
    }
  };

  handleChangeProviderName = (e, field, value) => {
    const { rates } = this.state;

    if (!rates && value === HOTEL_PROVIDER_VALUE.travelline) {
      this.props.tripService.getTravellineRates();
    }

    this.handleChangeJsonInput(field, value);

    this.setState({
      ProviderInn: '',
      ReportAmount: 0,
    });
  };

  handleChangeCheckbox = (e, field, value) => {
    this.setState((state) => {
      const newState = state;
      newState.JsonData[field] = value;

      return newState;
    });
  };

  handleChangeECLCCheckbox = (e, field, value) => {
    const {
      JsonData,
      PriceDetails,
    } = this.state;

    const priceField = field === 'CustomCheckInDate' ? 'EarlyCheckIn' : 'LateCheckOut';

    this.setState({
      JsonData: {
        ...JsonData,
        [field]: value,
      },
      PriceDetails: {
        ...PriceDetails,
        [priceField]: value ? PriceDetails[priceField] : 0,
      },
    }, () => this.handleCalculateBase());
  };

  handleChangeDate = (field, value) => {
    this.setState((state) => {
      const newState = state;
      const part = field.split('.');

      if (field === 'ReservationToday' || field === 'ReservationYesterday') {
        newState.JsonData.ReservationDate = field === 'ReservationToday'
          ? moment()
          : moment().subtract(1, 'days');
      } else if (field !== 'ReservationToday' && field !== 'ReservationYesterday') {
        if (part.length === 1 && field === 'CheckoutDate') {
          newState.JsonData[field] = value;
        } else if (part.length === 1 && field !== 'CheckoutDate') {
          newState.JsonData[field] = value;
        } else {
          newState.JsonData[part[0]][part[1]] = value;
        }
      }

      return newState;
    }, () => {
      if (field === 'ReservationToday' || field === 'ReservationYesterday') {
        this.validateDate('ReservationDate');
      }

      this.handleSetNightsCount();
    });
  };

  handleSetNightsCount = () => {
    const { JsonData: { CheckinDate, CheckoutDate } } = this.state;

    if (isValidMomentObject(CheckinDate) && isValidMomentObject(CheckoutDate)) {
      const diff = diffDays(momentObject(CheckinDate), momentObject(CheckoutDate));
      this.handleChangeJsonInput(FIELDS.NIGHTS_COUNT, diff);
    }
  };

  handleFocusCheckoutDate = () => {
    const { CheckinDate, CheckoutDate } = this.state.JsonData;
    const checkinDateExist = moment.isMoment(CheckinDate) ? CheckinDate.month() : null;
    const checkoutDateExist = moment.isMoment(CheckoutDate) ? CheckoutDate.month() : null;

    if (checkinDateExist && !checkoutDateExist) {
      this.setState({
        ...this.state,
        JsonData: {
          ...this.state.JsonData,
          CheckoutDate: CheckinDate,
        },
      });
    }
  };

  handleChangeVatRadio(field, value) {
    const { PriceDetails } = this.state;

    this.setState({
      PriceDetails: {
        ...PriceDetails,
        hasVat: value,
      },
    });
  }

  handleChangeVatReady = () => {
    this.setState({
      InternalVat: {
        VatReady: false,
        VatInfo: null,
      },
      PriceDetails: {
        ...this.state.PriceDetails,
        hasVat: VATVALUE.WITHOUT,
      },
    });
  };

  handleChangeVatDetailsRadio = (field, value) => {
    this.setState({
      internalVatDialogValue: {
        ...this.state.internalVatDialogValue,
        Amount: ((value !== VAT_VALUE_BY_VAT_BLOCK.VAT20
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT7
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT5)
        && this.state.internalVatDialogValue.Amount)
          ? 0
          : this.state.internalVatDialogValue.Amount,
        RackRate: ((value !== VAT_VALUE_BY_VAT_BLOCK.VAT20
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT7
          || value !== VAT_VALUE_BY_VAT_BLOCK.VAT5)
        && this.state.internalVatDialogValue.RackRate)
          ? 0
          : this.state.internalVatDialogValue.RackRate,
        [field]: value,
      },
    });
  };

  handleChangeVatDetailsPriceInput = (e, field, value) => {
    this.setState({
      internalVatDialogValue: {
        ...this.state.internalVatDialogValue,
        [field]: value,
      },
    });
  };

  handleChangeRackRateInput = (e, field, value) => {
    this.setState({
      internalVatDialogValue: {
        ...this.state.internalVatDialogValue,
        [field]: value,
      },
    });
  };

  handleConfirmVatDialog = async () => {
    const { tripService } = this.props;
    const {
      JsonData: { ProviderName },
      PriceDetails: { Base, commission },
      internalVatDialogValue: {
        Rate,
        RackRate,
        Amount,
      },
    } = this.state;

    if (Rate === VATVALUE.WITHOUT) {
      return this.setState({
        showVatDialog: false,
        PriceDetails: {
          ...this.state.PriceDetails,
          hasVat: VATVALUE.WITHOUT,
        },
        InternalVat: {
          VatReady: true,
          VatInfo: {
            HasVat: false,
            RackRate,
            VatDetails: [],
          },
        },
        internalVatDialogValue: {
          Amount: 0,
          RackRate: 0,
          Rate: '',
        },
      });
    }

    let vatStatus;
    // TODO перенести в сервис
    const isAutoCalc = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(ProviderName);
    let amount = Amount;

    try {
      if (isAutoCalc) {
        amount = await tripService.getVatAmount({
          Rate: Number(Rate),
          Amount: Base,
        });
      }

      const obj = {
        1: {
          Base,
          Commission: commission,
          RackRate,
          Provider: ProviderName,
          VatDetails: [
            {
              Rate: Number(Rate),
              Amount: isAutoCalc ? amount.Vat : amount,
            },
          ],
        },
      };

      const { 1: { ShowVat, Status } } = await tripService.getValidation(obj);

      vatStatus = Status;

      if (Status !== VAT_ERROR_STATUS.SUCCESS) {
        throw new Error();
      }

      return this.setState({
        showVatDialog: false,
        InternalVat: {
          VatReady: true,
          VatInfo: {
            HasVat: true,
            RackRate,
            VatDetails: [
              {
                Rate: Number(Rate),
                Amount: isAutoCalc ? amount.Vat : Amount,
              },
            ],
          },
        },
        PriceDetails: {
          ...this.state.PriceDetails,
          hasVat: ShowVat ? VATVALUE.WITH : VATVALUE.WITHOUT,
        },
        internalVatDialogValue: {
          Amount: 0,
          RackRate: 0,
          Rate: '',
        },
      });
    } catch (e) {
      return this.setState({ showNotificationDialog: true, vatErrorText: getVatErrorText(vatStatus) });
    }
  };

  handleAddCancellation = () => {
    const { CancellationPenalties } = this.state;

    let newCancellationPenalties = [];

    if (CancellationPenalties) newCancellationPenalties = [...CancellationPenalties];

    newCancellationPenalties.push({
      From: null,
      Total: '',
      Base: '',
      BaseCurrency: '',
      MFInCurrency: '',
      MFRubPenalties: '',
      ProviderPrice: '',
      Additional: false,
    });

    this.setState({ CancellationPenalties: newCancellationPenalties });
  };

  handleRemoveCancellation = (i) => {
    const newCancellationPenalties = [...this.state.CancellationPenalties];

    const changeItems = (index) => [
      ...newCancellationPenalties.slice(0, index),
      ...newCancellationPenalties.slice(index + 1),
    ];

    this.setState({ CancellationPenalties: changeItems(i) });
  };

  handleChangePenaltyInput = (field, value, i) => {
    const { CancellationPenalties, JsonData } = this.state;

    if (!CancellationPenalties) {
      const firstItem = {
        From: value,
        Total: '',
        Base: '',
        TotalCurrency: '',
        BaseCurrency: '',
        ProviderPrice: '',
        Additional: false,
        IsCustomCancellation: false,
      };

      return this.setState({ CancellationPenalties: [firstItem] });
    }

    const cancellationPenalties = CancellationPenalties.map((item, index) => {
      if (index === i) {
        const penalty = item;
        penalty[field] = value;

        return penalty;
      }

      return item;
    });

    this.setState({
      JsonData: {
        ...JsonData,
        CancellationPenalties: cancellationPenalties,
      },
    });

    return this.setState({ CancellationPenalties: cancellationPenalties });
  };

  handleAddVat = () => this.setState({ showVatDialog: true });

  handleChangeHasVat = (value) => {
    this.setState({
      PriceDetails: {
        ...this.state.PriceDetails,
        hasVat: value === VATVALUE.WITH ? VATVALUE.WITH : VATVALUE.WITHOUT,
      },
    });
  };

  closeVatDialog = () => {
    this.setState({
      showVatDialog: false,
      internalVatDialogValue: {
        Amount: 0,
        RackRate: 0,
        Rate: '',
      },
    });
  };

  closeErrorVatDialog = () => {
    this.setState({ showNotificationDialog: false });
  };

  handleConfirm = () => {
    const {
      PriceDetails,
      JsonData,
      CompanyId,
      ProjectId,
      EmployeesList,
      DepartmentId,
      InternalVat,
    } = this.state;

    const Employee = EmployeesList[0];

    const {
      isValid, validation, bigDateInterval, errors,
    } = this.validationSubmit({
      Employee,
      JsonData,
      PriceDetails,
      InternalVat,
      CompanyId,
      ProjectId,
      DepartmentId,
    });

    if (bigDateInterval) {
      this.setState({ showBigDateIntervalDialog: true });

      return;
    }

    if (isValid) {
      this.callPropsHandleConfirm();
    } else {
      scrollToErrMessage();

      this.setState({
        validation: { ...validation },
        errors,
      });
    }
  };

  handleCalculateCurrency = async () => {
    const { accountId, tripService } = this.props;
    const {
      CountryCode,
      JsonData: { ProviderName, Room },
      CancellationPenalties,
      PriceDetails,
      CurrencyData,
      JsonData,
    } = this.state;
    const {
      Inclusive, MFInCurrency, CurrencyName, RequiredTotalInRub,
    } = CurrencyData;

    const penalties = CancellationPenalties[0].From
      ? CancellationPenalties.map(({ From, ProviderPrice }) => ({
        From: formatDate(From, DATE_WITHOUT_TIME_ZONE),
        ProviderPrice,
      }))
      : [];

    const data = {
      AccountId: accountId,
      CountryCode,
      Provider: ProviderName,
      Penalties: penalties,
      ProviderPrice: Inclusive,
      MF: MFInCurrency,
      Currency: CurrencyName,
      RequiredTotalInRub: RequiredTotalInRub || 0,
    };

    const res = await tripService.getPricesBookingMarkup(data);

    if (res) {
      const {
        Penalties, Price, Currency: CurrencyInf, PriceInCurrency, PenaltiesStart,
      } = res;
      const {
        Name, Value, MultiplierPercent, ValueWithMultiplier, DateUTC, Multiplier,
      } = CurrencyInf;
      const {
        Base: BaseCurrency, Commission: CommissionCurrency, Total, MF: MFCurrency, ProviderPrice,
      } = PriceInCurrency;
      const {
        Base, Commission, MF,
      } = Price;

      const cancellationPenaltiesCurrency = Penalties.map((item) => ({
        ...item,
        From: momentObject(item.From),
        BaseCurrency: item.BaseInCurrency,
        ProviderPrice: item.ProviderPrice,
        MFRubPenalties: item.MF,
        MFInCurrency: item.MFInCurrency,
      }));

      this.setState({
        PriceDetails: {
          ...PriceDetails,
          Base,
          commission: Commission,
        },
        CurrencyData: {
          ...CurrencyData,
          CurrencyName: Name,
          CurrencyValue: Value,
          CurrencyMultiplierPercent: MultiplierPercent,
          CurrencyValueWithMultiplier: ValueWithMultiplier,
          DateUTC,
          Multiplier,
          Base: BaseCurrency,
          Commission: CommissionCurrency,
          Total,
          MFInCurrency: MFCurrency,
          Inclusive: ProviderPrice,
          MF,
        },
        JsonData: {
          ...JsonData,
          Room: {
            ...Room,
            FreeCancellation: PenaltiesStart ? momentObject(PenaltiesStart) : Room.FreeCancellation,
          },
        },
        CancellationPenalties: cancellationPenaltiesCurrency.length
          ? cancellationPenaltiesCurrency
          : CancellationPenalties,
      });
    }
  };

  callPropsHandleConfirm = () => {
    const {
      OrderTripItemId,
      ServiceType,
      Status,
      PriceDetails: oldPriceDetails,
      JsonData,
      EmployeesList,
      CompanyId,
      ProjectId,
      DepartmentId,
      Contact,
      UserAnalytics,
      NightsCount,
      NightsPrice,
      CountryCode,
      ProviderInn,
      ReportAmount,
      InternalVat: oldInternalVat,
      CurrencyData: {
        Base,
        Commission,
        Total,
        CurrencyName,
        CurrencyValue,
        DateUTC,
        MFInCurrency,
        MF,
        Multiplier,
        CurrencyMultiplierPercent,
        CurrencyValueWithMultiplier,
        Inclusive,
      },
      Meal,
    } = this.state;

    const {
      addTrip, guidService, onConfirm, hotel, isItemCart,
    } = this.props;

    const Guid = guidService.generate();

    const stateToSave = JSON.parse(JSON.stringify(this.state));
    stateToSave.Guid = Guid;

    const employees = [];

    if (!isItemCart) {
      EmployeesList.forEach((item) => {
        if (item.Id) employees.push(item.Id);
      });
    } else {
      EmployeesList.forEach((item) => {
        if (item.Id) {
          employees.push({
            Id: item.Id,
            DocumentId: !!item.Documents.length ? item.Documents[0].Id : 0,
          });
        }
      });
    }

    JsonData.EmployeeIds = employees;
    JsonData.NightsPrice = JsonData.NightsPrice || 0;
    JsonData.Room.FreeCancellation = JsonData.Room.FreeCancellation || null;

    if (
      !isItemCart
      && JsonData.ProviderName === HOTEL_PROVIDER_VALUE.ostrovok
      && CountryCode.length
      && CountryCode !== 'RU'
    ) {
      JsonData.BreakfastName = JsonData.Breakfast ? JsonData.BreakfastName : FIELD_VALUE;
    }

    this.normalizeDate(JsonData);

    const commission = parseFloat(oldPriceDetails.commission, 10) || 0;

    const PriceDetails = {
      ...oldPriceDetails,
      EarlyCheckIn: parseFloat(oldPriceDetails.EarlyCheckIn, 10) || 0,
      LateCheckOut: parseFloat(oldPriceDetails.LateCheckOut, 10) || 0,
      hasVat: oldPriceDetails.hasVat === VATVALUE.WITH,
      commission,
    };

    JsonData.PriceDetails = PriceDetails;

    const InternalVat = oldInternalVat.VatReady
      ? {
        VatReady: oldInternalVat.VatReady,
        VatInfo: {
          HasVat: oldInternalVat.VatInfo.HasVat,
          RackRate: oldInternalVat.VatInfo.RackRate || 0,
          VatDetails: oldInternalVat.VatInfo.HasVat
            ? oldInternalVat.VatInfo.VatDetails
            : [],
        },
      }
      : {
        VatReady: false,
        VatInfo: null,
      };
    const currencyData = JsonData.ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? {
        BaseInCurrency: Base,
        TotalInCurrency: Total,
        CommissionInCurrency: Commission,
        MF,
        MFInCurrency,
        CurrencyName,
        CurrencyValue,
        CurrencyMultiplier: Multiplier,
        CurrencyDateUTC: DateUTC,
        CurrencyMultiplierPercent,
        CurrencyValueWithMultiplier,
        Inclusive,
      }
      : null;

    const item = {
      OrderTripItemId,
      OrderTripId: 0,
      CompanyId: parseInt(CompanyId, 10),
      ProjectId: parseInt(ProjectId, 10),
      DepartmentId: parseInt(DepartmentId, 10),

      Status,
      ServiceType,

      PriceDetails,
      InternalVat,
      Contact,
      CurrencyData: currencyData,
      Meal: prepareMealForSave(Meal),

      UserAnalytics,
      Guid,
      NightsPrice,
      NightsCount,
      EmployeesList: EmployeesList.filter(({ Id }) => !!Id),
      indexEdited: hotel.indexEdited,
      isEdit: hotel.isEdit,
      Id: hotel.Id,
      ProviderInn,
      ReportAmount: ReportAmount || null,

      JsonData: JSON.stringify(JsonData),
    };

    addTrip(stateToSave);
    onConfirm(item);
  };

  closeBigDateIntervalDialog = () => {
    this.setState({ showBigDateIntervalDialog: false });
  };

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  };

  handleSelectEmployee = async (item, index) => {
    const { travelPolicyService, tripService } = this.props;

    const currentEmployee = await tripService.getEmployeeDataByID(item.Id);

    const { CompanyId } = this.state;

    const newEmployeesList = [...this.state.EmployeesList];
    newEmployeesList[index] = currentEmployee;

    this.setState({
      Departments: findDepartments(newEmployeesList[0], CompanyId),
      EmployeeId: item.Id,
      DepartmentId: 0,
    });

    const HOTEL = travelPolicyService.getServiceTypeConst().HOTEL;

    let valid = this.state.validation.EmployeeFirst;
    if (index === 0) valid = '';

    const cb = async () => {
      const travelPolicy = await travelPolicyService.getEmployeeTP(item.Id);
      const { Value: rate } = await travelPolicyService.getRateForType(travelPolicy.HotelRule);

      const tpDetails = travelPolicyService.getTPDetails(travelPolicy, HOTEL, rate);

      newEmployeesList[index] = {
        ...newEmployeesList[index],
        tpDetails,
      };

      this.setState({
        EmployeesList: newEmployeesList,
        showAlertTP: Boolean(tpDetails),
      });
    };

    this.setState({
      EmployeesList: newEmployeesList,
      validation: {
        ...this.state.validation,
        EmployeeFirst: valid,
      },
    }, cb);
  };

  handleRemoveEmployeeSuggest = (index) => {
    const {
      EmployeesList, DepartmentId, CompanyId,
    } = this.state;

    const newEmployeesList = EmployeesList.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {};
      }

      return item;
    });

    const noEmployees = newEmployeesList.every(({ Id }) => !Id);

    this.setState({
      EmployeesList: newEmployeesList,
      DepartmentId: noEmployees || Object.keys(newEmployeesList[0]).length === 0 ? 0 : DepartmentId,
      Departments: noEmployees ? [] : findDepartments(newEmployeesList[0], CompanyId),
    });
  };

  handleSelectCompany = ({ id }) => {
    const { tripService, mandatoryProject } = this.props;
    const newMandatory = isMandatory(mandatoryProject, id);
    const result = tripService.hotelTripFieldValidation('CompanyId', id);
    const projectResult = mandatoryProject ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, null) : '';
    const newEmployeesList = this.state.EmployeesList.map(() => ({}));

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.CompanyId = id;
      newState.mandatoryProject = newMandatory;
      newState.EmployeesList = newEmployeesList;
      newState.Departments = [];
      newState.validation.CompanyId = result;
      newState.validation.ProjectId = projectResult;
      newState.validation.EmployeeFirst = '';

      return newState;
    });
  };

  handleSelectProject = ({ id }) => {
    const { tripService } = this.props;
    const { mandatoryProject } = this.state;

    const result = mandatoryProject ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleUpdateMainHotelFields = ({
    Hotel: {
      Name,
      Address,
      City,
      RegionId,
      Phone,
      Stars,
      CheckinTime,
      CheckoutTime,
      CountryCode,
    },
    ProviderName,
    Contact,
  }) => {
    this.setState({
      CustomHotelName: Name,
      JsonData: {
        ...this.state.JsonData,
        ProviderName,
        Contact,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name,
          Address,
          City,
          RegionId: getRegionId(RegionId),
          Phone,
          Stars,
          CheckinTime,
          CheckoutTime,
          CountryCode,
        },
      },
      CountryCode,
    }, () => {
      this.validateInput(FIELDS.HOTELNAMEUPPER);
      this.validateInput(FIELDS.HOTELADDRESS);
      this.validateInput(FIELDS.HOTELCITY);
    });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { tripService } = this.props;
    const { Departments } = this.state;
    const result = Departments.length ? tripService.hotelTripFieldValidation(FIELDS.DEPARTMENTID, id) : '';
    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  getContractAndConnectHotel = async ({ Id }) => {
    const { tripService } = this.props;

    const {
      contract, connected, compared,
    } = await tripService.getContractConnectCompareHotel(Id);

    if (connected.length) {
      const isConnected = connected[0].Connected;

      if (isConnected && !contract) {
        this.setState({
          ...this.state,
          JsonData: {
            ...this.state.JsonData,
            ProviderName: HOTEL_PROVIDER_VALUE.travelline,
          },
        });
      } else if (!isConnected && contract) {
        this.setState({
          ...this.state,
          JsonData: {
            ...this.state.JsonData,
            ProviderName: HOTEL_PROVIDER_VALUE.contract,
          },
        });
      }
    }

    this.setState({
      ...this.state,
      contract: contract || {},
      connected: connected.length ? connected[0] : {},
      compared: compared || {},
    });
  };

  handleSelectSuggest = (field, value, hotel) => {
    const { tripService } = this.props;

    tripService.getHotelDetails(hotel.Id).then((res) => {
      const {
        Name, Address, City, Phone, Stars, CheckinTime, CheckoutTime, ClassificatorId, CountryCode, RegionId, Email,
      } = res;

      this.setState({
        ...this.state,
        hotelName: {
          ...this.state.hotelName,
          label: Name,
        },
        CustomHotelName: Name,
        CountryCode,
        JsonData: {
          ...this.state.JsonData,
          Hotel: {
            ...this.state.JsonData.Hotel,
            Name,
            Address,
            City,
            Phone,
            RegionId: getRegionId(RegionId),
            Stars,
            CheckinTime,
            CheckoutTime,
            ClassificatorId: ClassificatorId || null,
            CountryCode,
            Email,
          },
        },
      }, () => {
        this.validateInput(FIELDS.HOTELNAMEUPPER);
        this.validateInput(FIELDS.HOTELADDRESS);
        this.validateInput(FIELDS.HOTELCITY);
      });
    });

    this.getContractAndConnectHotel(hotel);
    this.getTLRates(hotel);
  };

  handleChangeSuggest = (e, field, value) => {
    const { tripService } = this.props;

    this.setState({
      ...this.state,
      hotelName: {
        ...this.state.hotelName,
        label: value,
      },
      JsonData: {
        ...this.state.JsonData,
        Hotel: {
          ...this.state.JsonData.Hotel,
          Name: value,
          ClassificatorId: null,
        },
      },
    }, () => {
      const hotelLabel = this.state.hotelName.label;
      if (hotelLabel) {
        tripService.autocompleteHotel(hotelLabel).then((res) => {
          const resFilter = res ? res.filter((item) => !item.IsRegion) : [];
          this.setState({
            ...this.state,
            hotelName: {
              ...this.state.hotelName,
              suggests: resFilter,
            },
          });
        });
      }
      const result = tripService.hotelTripFieldValidation(FIELDS.HOTELNAMEUPPER, value);

      this.setState({
        ...this.state,
        validation: {
          ...this.state.validation,
          Hotel: {
            ...this.state.validation.Hotel,
            Name: result,
          },
        },
      });
    });
  };

  handleChangeRoomCategory = (e, field, rateId) => {
    const { rates } = this.state;
    const { rate_name } = rates.find(({ id }) => id === rateId);

    this.setState({ ratePlaneId: rateId });
    this.handleChangeJsonInput(field, rate_name);
  };

  handleChangeContact = (value) => this.setState({ Contact: value });

  handleChangeStateInput = (field, value) => this.setState({ [field]: value });

  validationSubmit({
    Employee, JsonData, PriceDetails, InternalVat, CompanyId, ProjectId, DepartmentId,
  }) {
    const {
      tripService, analytics, tripAnalyticsValueIds: tripAnalyticsValues, projects, isItemCart,
    } = this.props;
    const {
      Departments,
      mandatoryProject,
      CancellationPenalties,
      UserAnalytics: serviceAnalyticsValues,
      CountryCode,
      ProviderInn,
      CurrencyData,
      JsonData: { Room: { FreeCancellation } },
    } = this.state;
    const {
      ProviderName, Breakfast, Hotel: { CheckinTime, CheckoutTime },
    } = JsonData;

    const projectMassage = projects.length
      ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    const employees = tripService.hotelTripFieldValidation(FIELDS.EMPLOYEES, Employee);
    const companyId = tripService.hotelTripFieldValidation(FIELDS.COMPANYID, CompanyId);
    const projectId = mandatoryProject ? projectMassage : '';
    const departmentId = Departments.length > 0 ? tripService.hotelTripFieldValidation(FIELDS.DEPARTMENTID, DepartmentId) : '';
    const reservedNum = !isItemCart ? tripService.hotelTripFieldValidation(FIELDS.RESERVATIONNUMBER, JsonData.ReservationNumber) : '';
    const reservedDate = !isItemCart ? tripService.hotelTripFieldValidation(FIELDS.RESERVATIONDATE, JsonData.ReservationDate) : '';
    const guest = tripService.hotelTripFieldValidation(FIELDS.GUESTCOUNT, JsonData.GuestCount);
    const name = tripService.hotelTripFieldValidation('Hotel.Name', JsonData.Hotel.Name);
    const address = tripService.hotelTripFieldValidation('Hotel.Address', JsonData.Hotel.Address);
    const city = tripService.hotelTripFieldValidation('Hotel.City', JsonData.Hotel.City);
    const price = tripService.hotelTripFieldValidation('Base', PriceDetails.Base);
    const commissionPrice = tripService.hotelTripFieldValidation('commission', PriceDetails.commission);
    const hasVat = tripService.hotelTripFieldValidation('HasVat', PriceDetails.hasVat);
    const checkin = tripService.hotelTripFieldValidation('CheckinDate', JsonData.CheckinDate);
    const inn = PROVIDERS_AVAILABLE_INN.includes(ProviderName)
      ? tripService.hotelTripFieldValidation(FIELDS.PROVIDER_INN, ProviderInn)
      : '';
    const earlyCheckIn = JsonData.CustomCheckInDate && tripService.hotelTripFieldValidation('EarlyCheckIn', PriceDetails.EarlyCheckIn);
    const lateCheckOut = JsonData.CustomCheckOutDate && tripService.hotelTripFieldValidation('LateCheckOut', PriceDetails.LateCheckOut);
    const breakfastName = !isItemCart && ProviderName === HOTEL_PROVIDER_VALUE.ostrovok && CountryCode.length && CountryCode !== 'RU' && Breakfast ? tripService.hotelTripFieldValidation(FIELDS.BREAKFASTNAME, JsonData.BreakfastName) : '';
    const currencyMarketingFee = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.MF_IN_CURRENCY, CurrencyData.MFInCurrency)
      : '';
    const currencyInclusive = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.CURRENCY_INCLUSIVE, CurrencyData.Inclusive)
      : '';
    const currencyBase = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.CURRENCY_BASE, CurrencyData.Base)
      : '';
    const currencyCommission = ProviderName === HOTEL_PROVIDER_VALUE.expedia
      ? tripService.hotelTripFieldValidation(FIELDS.CURRENCY_COMMISSION, CurrencyData.Base)
      : '';
    const penaltyTotal = CancellationPenalties && FreeCancellation
      ? CancellationPenalties.map(({ Total }) => (tripService.hotelTripFieldValidation(FIELDS.TOTAL_PENALTY, Total)))
      : [];
    const penaltyFrom = CancellationPenalties && FreeCancellation
      ? CancellationPenalties.map(({ From }) => (tripService.hotelTripFieldValidation(FIELDS.FROM_PENALTY, From)))
      : [];
    const penaltyBase = CancellationPenalties && FreeCancellation
      ? CancellationPenalties.map(({ Base, Additional }) => ((Additional && !Base) || (!Additional && typeof Base !== 'number')
        ? (tripService.hotelTripFieldValidation(FIELDS.BASE_PENALTY_FULL, Base))
        : ''))
      : [];
    const checkinTime = CheckinTime.length && !timePattern.test(CheckinTime) ? ERRORS.INVALID_FORMAT : '';
    const checkoutTime = CheckoutTime.length && !timePattern.test(CheckoutTime) ? ERRORS.INVALID_FORMAT : '';

    const total = penaltyTotal.filter((item) => item !== '').length !== 0;
    const from = penaltyFrom.filter((item) => item !== '').length !== 0;
    const base = penaltyBase.filter((item) => item !== '').length !== 0;

    const validInternalVat = !isItemCart
      ? InternalVat.VatReady === null
      || (InternalVat.VatReady
        && InternalVat.VatInfo.HasVat
        && InternalVat.VatInfo.VatDetails[0].Rate === 20
        && InternalVat.VatInfo.VatDetails[0].Amount === 0
      )
      : '';

    let checkout;

    if (isMoment(JsonData.CheckinDate)
      && JsonData.CheckinDate.isValid()
      && isMoment(JsonData.CheckoutDate)
      && JsonData.CheckoutDate.isValid()) {
      checkout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      checkout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUT_DATE, JsonData.CheckoutDate);
      checkout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    }

    const userAnalytics = validateAnalytics(tripAnalyticsValues, serviceAnalyticsValues, analytics);
    const hasUnsetRequiredAnalytics = Object.keys(userAnalytics).some((key) => !!userAnalytics[key]);

    if (
      employees
      || companyId
      || projectId
      || departmentId
      || reservedNum
      || reservedDate
      || guest
      || checkin
      || checkout
      || name
      || address
      || city
      || price
      || commissionPrice
      || hasVat
      || earlyCheckIn
      || lateCheckOut
      || total
      || from
      || base
      || hasUnsetRequiredAnalytics
      || breakfastName
      || inn
      || validInternalVat
      || currencyMarketingFee
      || currencyInclusive
      || currencyBase
      || currencyCommission
      || checkinTime
      || checkoutTime
    ) {
      return {
        isValid: false,
        validation: {
          EmployeeFirst: employees,
          ReservationNumber: reservedNum,
          ReservationDate: reservedDate,
          GuestCount: guest,
          CheckinDate: checkin,
          CheckoutDate: checkout,
          Hotel: {
            Name: name,
            Address: address,
            City: city,
          },
          Base: price,
          commission: commissionPrice,
          CompanyId: companyId,
          ProjectId: projectId,
          DepartmentId: departmentId,
          MandatoryProject: mandatoryProject,
          HasVat: hasVat,
          EarlyCheckIn: earlyCheckIn,
          LateCheckOut: lateCheckOut,
          Total: penaltyTotal,
          From: penaltyFrom,
          Penalty: { Base: penaltyBase },
          analytics: userAnalytics,
          BreakfastName: breakfastName,
          ProviderInn: inn,
          CurrencyInfo: {
            CurrencyMFInCurrency: currencyMarketingFee,
            CurrencyInclusive: currencyInclusive,
            CurrencyBase: currencyBase,
            CurrencyCommission: currencyCommission,
          },
        },
        errors: {
          [FIELDS.HOTELCHECKINTIME]: checkinTime,
          [FIELDS.HOTELCHECKOUTTIME]: checkoutTime,
        },
      };
    } else if (moment.duration(JsonData.CheckoutDate.diff(JsonData.CheckinDate)).as('days') > 10) {
      return { bigDateInterval: true };
    }

    return { isValid: true };
  }

  validateInput(field) {
    const { tripService } = this.props;
    const { JsonData } = this.state;
    const part = field.split('.');

    if (part.length === 1) {
      const result = tripService.hotelTripFieldValidation(field, JsonData[field]);

      this.setState((state) => {
        const newState = state;
        newState.validation[field] = result;

        return newState;
      });
    } else {
      const result = tripService.hotelTripFieldValidation(field, JsonData[part[0]][part[1]]);

      this.setState((state) => {
        const newState = state;
        newState.validation[part[0]][part[1]] = result;

        return newState;
      });
    }
  }

  validateDate = (field) => {
    const { JsonData } = this.state;
    const { tripService } = this.props;
    const { CheckinDate, CheckoutDate } = JsonData;

    let result;
    let resultCheckin;
    let resultCheckout;
    let resultCheckoutLess;

    const isDateBeforeOtherDate = !dateAfterOtherDate(JsonData);

    if (isDateBeforeOtherDate && field === FIELDS.CHECKOUT_DATE && isMoment(CheckoutDate) && CheckoutDate.isValid()) {
      resultCheckin = tripService.hotelTripFieldValidation(FIELDS.CHECKIN_DATE, JsonData);
      resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else if ((field === FIELDS.CHECKIN_DATE || field === FIELDS.CHECKOUT_DATE)
      && (CheckinDate || CheckoutDate)
      && (isValidMomentObject(CheckinDate) || isValidMomentObject(CheckoutDate))) {
      resultCheckin = tripService.hotelTripFieldValidation(FIELDS.CHECKIN_DATE, JsonData);
      resultCheckout = tripService.hotelTripFieldValidation(FIELDS.CHECKOUT_DATE, JsonData);
      resultCheckoutLess = tripService.hotelTripFieldValidation(FIELDS.CHECKOUTDATELESS, JsonData);
    } else {
      result = tripService.hotelTripFieldValidation(field, JsonData[field]);
    }

    if (resultCheckin || resultCheckout || resultCheckoutLess) {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
          CheckinDate: resultCheckin,
          CheckoutDate: resultCheckout || resultCheckoutLess,
        },
      });
    } else {
      this.setState({
        validation: {
          ...this.state.validation,
          [field]: result,
        },
      });
    }
  };

  validationPrice(e, field) {
    const result = this.props.tripService.hotelTripFieldValidation(field, this.state.PriceDetails[field]);

    this.setState((state) => {
      const newState = state;
      newState.validation[field] = result;

      return newState;
    });
  }

  validateProviderInn = (e, field) => {
    const { ProviderInn, validation } = this.state;

    const result = this.props.tripService.hotelTripFieldValidation(field, ProviderInn);

    this.setState({
      validation: {
        ...validation,
        ProviderInn: result,
      },
    });
  };

  validationCurrency = (e, field) => {
    const { tripService } = this.props;
    const { CurrencyData, validation } = this.state;

    const result = tripService.hotelTripFieldValidation(`Currency${field}`, CurrencyData[field]);

    this.setState({
      validation: {
        ...validation,
        CurrencyInfo: {
          ...validation.CurrencyInfo,
          [`Currency${field}`]: result,
        },
      },
    });
  };

  closeAlertTP = () => this.setState({ showAlertTP: false });

  getEmptyPenalties = () => {
    const { CancellationPenalties } = this.state;

    return CancellationPenalties && CancellationPenalties.length
      ? CancellationPenalties.find(({ ProviderPrice, From }) => !ProviderPrice || !From)
      : false;
  };

  handleEmployeeFocus = async () => {
    const { tripService } = this.props;
    const { CompanyId } = this.state;

    await tripService.autocompleteEmployees(CompanyId, '');

    this.setState({
      validation: {
        ...this.state.validation,
        EmployeeFirst: +CompanyId ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY,
      },
    });
  };

  handleAddEmployee = () => {
    const newEmployeesList = [...this.state.EmployeesList];

    newEmployeesList.push({});

    this.setState({ EmployeesList: newEmployeesList });
  };

  handleRemoveEmployee = () => {
    const {
      EmployeesList, DepartmentId, Departments,
    } = this.state;
    const newEmployeesList = [...EmployeesList];

    newEmployeesList.pop();

    this.setState({
      EmployeesList: newEmployeesList,
      DepartmentId: !newEmployeesList.length ? 0 : DepartmentId,
      Departments: !newEmployeesList.length ? [] : Departments,
    });
  };

  handleChangeCurrency = (field, value) => {
    this.setState(({
      CurrencyData: {
        ...this.state.CurrencyData,
        [field]: value,
      },
    }));
  };

  renderCurrency = () => {
    const {
      CurrencyData, JsonData: { ProviderName }, validation,
    } = this.state;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.expedia) return null;

    return (
      <Currency
        currencyInfo={ CurrencyData }
        providerName={ ProviderName }
        onChangeInput={ this.handleChangeCurrency }
        onBlur={ this.validationCurrency }
        valid={ validation }
        disabledInput
      />
    );
  };

  renderReason = () => {
    const { isItemCart, hotel: { isEdit } } = this.props;
    const { JsonData: { isCreateCart, Reason }, TravelPolicy } = this.state;

    if (!isCreateCart || !isItemCart || !isEdit || !Object.keys(TravelPolicy).length) return null;

    return (
      <Input
        field={ FIELDS.REASON }
        value={ Reason || '' }
        label={ TRIPSLABELS.REASON_TP }
        onChange={ (e, field, value) => this.handleChangeJsonInput(field, value) }
      />
    );
  };

  renderTooltipCalculate = (disabledCountAmount) => {
    const {
      showTooltipCalculate,
      CancellationPenalties,
      CurrencyData: { Inclusive, MFInCurrency },
      JsonData: { Room: { FreeCancellation } },
    } = this.state;

    if (!showTooltipCalculate || !disabledCountAmount) return null;

    const textTooltip = [];

    if (!Inclusive) {
      textTooltip.push(TRIPSLABELS.ADD_INCLUSIVE);
    }
    if (!MFInCurrency) {
      textTooltip.push(TRIPSLABELS.ADD_MF);
    }
    if (FreeCancellation && isEmptyPenalties(CancellationPenalties)) {
      textTooltip.push(TRIPSLABELS.ADD_PENALTIES);
    }

    return <Tooltip>{ textTooltip.join('. ') }</Tooltip>;
  };

  renderCalculateExpediaCurrency = (disabledCountAmount) => {
    const { JsonData: { ProviderName } } = this.state;

    if (ProviderName !== HOTEL_PROVIDER_VALUE.expedia) return null;

    return (
      <div className={ styles.row }>
        <div className='sw-tooltip-wrapper'>
          <div
            onMouseEnter={ () => this.setState({ showTooltipCalculate: true }) }
            onMouseLeave={ () => this.setState({ showTooltipCalculate: false }) }
          >
            <Button
              label={ PRICELABELS.CALCULATE_CURRENCY }
              onClick={ this.handleCalculateCurrency }
              disabled={ disabledCountAmount }
            />
          </div>
          { this.renderTooltipCalculate(disabledCountAmount) }
        </div>
      </div>
    );
  };

  renderAlertTP = () => (this.state.showAlertTP
    ? <AlertTP onClose={ this.closeAlertTP } isItemCart={ this.props.isItemCart } />
    : null);

  getFuncProps = () => ({
    onAddEmployee: this.handleAddEmployee,
    onRemoveEmployee: this.handleRemoveEmployee,
    onRemoveEmployeeSuggest: this.handleRemoveEmployeeSuggest,
    onSelectEmployeeSuggest: this.handleSelectEmployee,
    onChangeEmployeeSuggest: this.handleChangeEmployee,
    onEmployeeFocusSuggest: this.handleEmployeeFocus,
    onSelectCompany: this.handleSelectCompany,
    onSelectDepartment: this.handleSelectDepartment,
    onSelectProject: this.handleSelectProject,
    onChangeJsonInput: (e, field, value) => this.handleChangeJsonInput(field, value),
    onChangeStateInput: (e, field, value) => this.handleChangeStateInput(field, value),
    onChangeInput: (e, field, value) => this.handleChangeContact(value),
    onValidateInput: (e, value) => this.validateInput(value),
    onChangeTimeInput: (e, field, value) => this.handleChangeTimeInput(e, field, value),
    onBlurTimeInput: (e, field) => this.handleBlurTimeInput(e, field),
    onChangeDate: this.handleChangeDate,
    onValidateDate: this.validateDate,
    onSelectSuggest: this.handleSelectSuggest,
    onChangeSuggest: this.handleChangeSuggest,
    onChangeECLCCheckbox: this.handleChangeECLCCheckbox,
    onChangePriceDetailsInput: this.handleChangePriceInput,
    onChangeCheckbox: this.handleChangeCheckbox,
    onChangeRoomCategory: this.handleChangeRoomCategory,
    onChangeProviderName: this.handleChangeProviderName,
    onFocusCheckoutDate: this.handleFocusCheckoutDate,
    onAddCancellation: this.handleAddCancellation,
    onRemoveCancellation: this.handleRemoveCancellation,
    onChangePenaltyInput: this.handleChangePenaltyInput,
    onResetMainHotelFields: this.resetMainHotelFields,
    onChangeNumberInput: (e, field, value) => this.handleChangeNumberInput(field, value),
    onChangeReportAmount: (e, field, value) => this.handleChangeReportAmount(field, value),
    onValidateInn: this.validateProviderInn,
  });

  renderBigDateIntervalDialog = () => {
    const { showBigDateIntervalDialog, JsonData } = this.state;
    if (!showBigDateIntervalDialog) return null;

    const duration = moment.duration(JsonData.CheckoutDate.diff(JsonData.CheckinDate) / MILLISECONDS_IN_DAY, 'days').humanize();

    return (
      <Dialog onClick={ this.closeBigDateIntervalDialog }>
        <div className={ styles.dialog }>
          <div>
            Вы создаете бронирование на
            { duration }
            .
          </div>
          <p>
            Дата заезда:
            { JsonData.CheckinDate.format(SHORT_DEFAULT_DATE) }
          </p>
          <p>
            Дата выезда:
            { JsonData.CheckoutDate.format(SHORT_DEFAULT_DATE) }
          </p>
          <div className={ `${styles.row} ${styles.buttons}` }>
            <FlatButton
              label='все равно продолжить'
              onClick={ this.callPropsHandleConfirm }
            />
            <Button
              label='изменить'
              onClick={ this.closeBigDateIntervalDialog }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  renderVatNotificationDialog = () => {
    const { showNotificationDialog, vatErrorText } = this.state;

    if (!showNotificationDialog) return null;

    return (
      <VatErrorDialog text={ vatErrorText } onClose={ this.closeErrorVatDialog } />
    );
  };

  renderVatDialog = () => {
    const {
      showVatDialog, JsonData: { ProviderName }, internalVatDialogValue,
    } = this.state;

    if (!showVatDialog) return null;

    return (
      <VatDialog
        internalVat={ internalVatDialogValue }
        providerName={ ProviderName }
        onChangeRadio={ this.handleChangeVatDetailsRadio }
        onChangeInput={ this.handleChangeVatDetailsPriceInput }
        onChangeChangeRackRate={ this.handleChangeRackRateInput }
        onCloseDialog={ this.closeVatDialog }
        onConfirm={ this.handleConfirmVatDialog }
      />
    );
  };

  onBlurBase = (e, field) => {
    const {
      PriceDetails, InternalVat, preBasePrice,
    } = this.state;

    const isChangePrice = preBasePrice !== PriceDetails.Base;

    const newVat = isChangePrice
      ? {
        VatReady: null,
        VatInfo: null,
      }
      : InternalVat;
    const newPrice = isChangePrice
      ? {
        ...PriceDetails,
        hasVat: '',
      }
      : PriceDetails;

    this.setState({
      InternalVat: newVat,
      PriceDetails: newPrice,
      preBasePrice: PriceDetails.Base,
    }, () => this.validationPrice(e, field));
  };

  render() {
    const {
      PriceDetails,
      Meal,
      EmployeesList,
      hotelName,
      CustomHotelName,
      rates,
      ratePlaneId,
      connected,
      compared,
      validation,
      travellers,
      Departments,
      DepartmentId,
      EmployeeId,
      ProjectId,
      CompanyId,
      Contact,
      CancellationPenalties,
      isNewTrip,
      UserAnalytics,
      errors,
      contract,
      CountryCode,
      ProviderInn,
      ReportAmount,
      JsonData,
      InternalVat,
      JsonData: { ProviderName },
      CurrencyData: { Inclusive, MFInCurrency },
      IsFromOrderByNumber,
    } = this.state;

    const {
      projects,
      companies,
      analytics,
      tripAnalyticsValueIds,
      tripService,
      mandatoryProject,
      isItemCart,
      labelButton,
    } = this.props;

    const data = this.state.JsonData;
    const hotelProps = {
      employeesList: EmployeesList,
      employees: this.state.employeesList,
      travellers,
      departments: Departments,
      projects,
      companies,
      providerNameOptions: this.providerNameOptions,
      departmentId: DepartmentId,
      employeeId: EmployeeId,
      projectId: ProjectId,
      companyId: CompanyId,
      hotelName,
      CustomHotelName,
      contact: Contact,
      ProviderInn,
      ReportAmount,
    };

    const funcProps = this.getFuncProps();

    const bigDateIntervalDialog = this.renderBigDateIntervalDialog();

    const vatAmount = InternalVat.VatReady && InternalVat.VatInfo.HasVat ? InternalVat.VatInfo.VatDetails[0].Amount : 0;
    const vatReady = InternalVat.VatReady === null ? false : !InternalVat.VatReady;
    const disabledVatReady = InternalVat.VatReady === null ? false : !InternalVat.VatReady;

    const isExpedia = ProviderName === HOTEL_PROVIDER_VALUE.expedia;
    const disabledCountAmount = !Inclusive
      || !MFInCurrency
      || (data.Room.FreeCancellation && isEmptyPenalties(CancellationPenalties));

    return (
      <div className={ styles.wrap }>
        <CommonFields
          isCreateOrder
          hotelProps={ hotelProps }
          data={ data }
          rates={ rates }
          ratePlaneId={ ratePlaneId }
          connected={ connected }
          compared={ compared }
          validation={ validation }
          priceDetails={ PriceDetails }
          meal={ Meal }
          funcProps={ funcProps }
          cancellationPenalties={ CancellationPenalties }
          isNewTrip={ isNewTrip }
          analytics={ analytics }
          tripAnalyticsValues={ tripAnalyticsValueIds }
          serviceAnalyticsValues={ UserAnalytics }
          handleSelectAnalytics={ this.handleSelectAnalytics }
          errors={ errors }
          contract={ contract }
          country={ CountryCode }
          tripService={ tripService }
          mandatoryProject={ mandatoryProject }
          updateMainHotelFields={ this.handleUpdateMainHotelFields }
          isItemCart={ isItemCart }
          isFromOrderByNumber={ IsFromOrderByNumber }
        />
        <div className={ `${styles.row} ${styles.panel}` }>
          <h4>Цена</h4>
          <div className={ styles.row }>
            <div className={ styles['col-1-5'] }>
              <Input
                field='Base'
                value={ PriceDetails.Base }
                label='Цена поставщика'
                onChange={ (e, field, value) => this.handleChangePriceInput(e, field, value) }
                onBlur={ (e, field) => this.onBlurBase(e, field) }
                valid={ validation.Base }
                disabled={ isExpedia }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onKeyDown={ preventKeyNotNumber }
              />
            </div>
            <div className={ styles['col-1-5'] }>
              <Input
                field='commission'
                value={ PriceDetails.commission }
                label='Наценка Smartway'
                onChange={ (e, field, value) => this.handleChangePriceInput(e, field, value) }
                onBlur={ (e, field) => this.validationPrice(e, field) }
                valid={ validation.commission }
                disabled={ isExpedia }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                onKeyDown={ preventKeyNotNumber }
              />
            </div>
            <div className={ styles['col-1-2'] }>
              <VatBlock
                vatReady={ vatReady }
                vatAmount={ vatAmount }
                providerName={ JsonData.ProviderName }
                disabledVatReady={ disabledVatReady }
                validation={ validation }
                base={ PriceDetails.Base }
                hasVat={ PriceDetails.hasVat }
                onChangeVatReady={ this.handleChangeVatReady }
                onAddVat={ this.handleAddVat }
                onChangeHasVat={ this.handleChangeHasVat }
                isItemCart={ isItemCart }
              />
            </div>
          </div>
          { this.renderCurrency() }
          { this.renderCalculateExpediaCurrency(disabledCountAmount) }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            { this.renderReason() }
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              label={ labelButton }
              onClick={ () => this.handleConfirm() }
            />
          </div>
        </div>
        { bigDateIntervalDialog }
        { this.renderAlertTP() }
        { this.renderVatDialog() }
        { this.renderVatNotificationDialog() }
      </div>
    );
  }
}

export default NewHotelForm;
